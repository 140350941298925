<template>
  <div class="content" :style="{'background':'#'+data.bgColor}">

    <img class="top" :src="data.imgUrl" alt="">
    <!-- <div class="go" @click='goodsDetail'>立即前往</div> -->
    <img
      class="go"
      src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-03-13/16/yuelvhui9yIpLfLO321584089624.gif"
      alt=""
      @click="goodsDetail"
    >
    <div class="rules" :style="{'background':'#'+data.ruleCoulr}">
      <div class="header">规则说明</div>
      <div v-for="(item,index) of data.explain" :key="index" class="list">{{ index + 1 }} : {{ item }}</div>
    </div>
    <!--下载app二维码-->
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="../../../assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import { getBrandDiscount } from '@/services/comeOn.js'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  name: 'BrandDiscountIndex',
  data() {
    return {
      showimg: false,
      data: {}, // 数据
      type: 1, // 海报活动的类型
      codeNumber: '' // 邀请码
    }
  },
  created() {
    this.initData()
    this.postersData()
  },
  mounted() {

  },
  methods: {
    // url数据
    getQueryVariable(variable) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    postersData() {
      const that = this
      const data = {
        type: that.type,
        postType: 2,
        uid: window.localStorage.getItem('uid'), // 领取者的ID
        room_id: that.getQueryVariable('room_id') ? that.getQueryVariable('room_id') : 0 // 领取者的ID
      }
      getBrandDiscount(data).then(res => {
        if (Number(res.code) === 200) {
          that.data = res.data
          const shareInfo = {
            title: that.data.shareData.h5shareData.title, // 分享标题
            desc: that.data.shareData.h5shareData.desc, // 分享描述
            link: window.location.href + '?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.data.shareData.h5shareData.pic // 分享图标
          }
          wxConfigInit(shareInfo)
          // this.$store.dispatch('wxConfigInit', shareInfo)
        } else {
          Toast(res.msg)
          that.noCanGet = true
        }
      })
    },
    goodsDetail() {
      window.location.href = this.data.link
      // this.goApp()
    },
    initData() {
      this.codeNumber = this.getQueryVariable('codeNumber')
      this.type = this.getQueryVariable('type') ? this.getQueryVariable('type') : 1
    }

  }
}
</script>

<style lang="less" scoped>
html, body, #app, .content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  .top {
    width: 100%;
    /* min-height: 5.55rem; */
    display: block;
  }

  .go {
    width: 100%;
    margin-top: .14rem;
    display: block;
  }

  .rules {
    width: 6.90rem;
    padding: .4rem .45rem 1rem .35rem;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 1.02rem;
    font-size: .28rem;
    color: #fff;
    border-radius: .16rem;
  }

  .rules .list {
    font-size: .26rem;
    margin-top: .1rem;
  }

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 99;

    .picture-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .picture {
        display: block;
        width: 280px;
        // height: 360px;
        background: rebeccapurple;
        margin: 0 auto;
      }

      .btn {
        width: 290px;
        height: 44px;
        border-radius: 22px;
        background: #ed2e2e;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        margin-top: 15px;
      }
    }

    .sku {
      width: 100%;
      min-height: 500px;
      border-radius: 8px 8px 0 0;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
      box-sizing: border-box;
      padding: 14px;

      .tp {
        display: flex;
        width: 100%;
        position: relative;
        margin-bottom: 20px;

        .goods-cover {
          width: 78px;
          height: 78px;
          border-radius: 4px;
          background: #e0e0e0;
        }

        .goods-price {
          width: 250px;
          height: 78px;
          display: flex;
          // align-items: center;
          flex-direction: column;
          justify-content: space-around;
          box-sizing: border-box;
          padding-left: 12px;

          .pris {
            text-align: left;

            .hjy {
              color: #ed2e2e;
              font-size: 13px;
            }

            .hjy-count {
              color: #ed2e2e;
              font-size: 18px;
              font-weight: bold;
              margin-right: 12px;
            }

            .spe-icon {
              font-size: 14px;
              color: #ed2e2e;
              font-weight: bold;
              margin-left: 4px;
            }

            .gwj {
              color: #141f33;
              font-size: 13px;
            }
          }

          .guige {
            text-align: left;
            font-size: 13px;
            color: #99a0ad;
          }

          .close {
            width: 16px;
            height: 16px;
            background: url("../../../assets/images/closesku.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 4px;
            top: 4px;
          }
        }
      }

      .out {
        height: 200px;
        overflow: hidden;
        margin-bottom: 50px;

        .inner {
          height: 200px;
          overflow: auto;
        }
      }

      .status-box {
        width: 100%;
        margin-bottom: 8px;

        .tits {
          font-size: 16px;
          color: #141f33;
          margin-bottom: 12px;
          text-align: left;
        }

        .status {
          width: 100%;
          overflow: hidden;

          .box {
            width: auto;
            padding: 6px 20px;
            background: #f0f2f5;
            border-radius: 4px;
            font-size: 14px;
            color: #141414;
            display: inline-block;
            margin-right: 12px;
            margin-bottom: 12px;
            float: left;
          }

          .act {
            background: #fae9e8;
            color: #ed2e2e;
          }
        }
      }
    }

    .num-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #141f33;
      margin-bottom: 23px;

      .handles {
        .edge,
        .add {
          display: inline-block;
          width: 28px;
          height: 28px;
          background: #f0f2f5;
          border-radius: 4px;
          color: #616b80;
          font-size: 12px;
          line-height: 28px;
        }

        .cou {
          display: inline-block;
          width: 45px;
          height: 28px;
          background: #f0f2f5;
          border-radius: 4px;
          color: #616b80;
          font-size: 12px;
          line-height: 28px;
          margin: 0 4px;
        }
      }
    }

    .code-txt {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #99a0ad;
      margin-bottom: 4px;
    }

    .btn-box {
      width: 100%;
      height: 44px;
      border-radius: 22px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .btn1 {
        width: 50%;
        height: 100%;
        line-height: 44px;
        background: #201e1d;
        color: #fff;
        font-size: 16px;
      }

      .btn2 {
        background: #ff001c;
      }

      .btn3 {
        width: 100%;
        height: 100%;
        line-height: 44px;
        color: #fff;
        font-size: 16px;
        background: #ff001c;
      }
    }
  }
}
</style>
